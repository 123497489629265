<template>
<div class="app-index-page tenmiles-page">
  <div class="page__wrapper">
    <div class="page__header">
      <a-typography-title :level="1" :style="{ display: 'inline-block', marginRight: '12px', marginBottom: 0 }">
        {{ $store.state.auth.info.coop_info.name }}
      </a-typography-title>
      <span :style="{ fontSize: '1rem' }">Id: {{ $store.state.auth.info.coop_info.coop_id }}</span>
    </div>

    <div class="page__body">
      <div class="body-item">
        <a-typography-title :level="3">操作</a-typography-title>

        <div class="item-actions">
          <!-- <a-button class="action-btn" large @click="toTransactionPage">兌換所交易</a-button>
          <a-button class="action-btn" large @click="toAccountingPage">兌換所收支紀錄</a-button> -->
          <a-button class="action-btn" large @click="toFeeAccountingPage">手續費收支紀錄</a-button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import { defineComponent } from 'vue'
import { message } from 'ant-design-vue'
import { DownOutlined } from '@ant-design/icons-vue'

const columns = [{
  title: 'Id',
  dataIndex: 'id',
  key: 'id'
}, {
  title: '姓名',
  dataIndex: 'name',
  key: 'name'
}, {
  title: '操作',
  key: 'action'
}]

export default defineComponent({
  components: {
    DownOutlined
  },
  setup() {
    return {
      columns,
      message
    }
  },
  async mounted() {
    window.scrollTo(0, 0);

    this.loading = true;
    await this.getExchangeAccounts();
    await this.getMemberAccounts();
    this.loading = false;
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    /* router */
    toTransactionPage() {
      this.$router.push({ name: 'AppTransaction' });
    },
    toAccountingPage() {
      this.$router.push({ name: 'AppAccounting' });
    },
    toFeeAccountingPage() {
      this.$router.push({ name: 'AppFeeAccounting' });
    },
    toRecordsPage() {
      this.$router.push({ name: 'AppTransferRecords' });
    },

    /* api */
    async getExchangeAccounts() {
      const url = process.env.VUE_APP_API + `/users?role=3&coop_info.id=${this.$store.state.auth.info.coop_info.id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.token}`
        }
      }
      const response = await axios.get(url, config);
      const resData = response.data;
      this.exchangeAccounts = resData;
      console.log(resData)
    },
    async getMemberAccounts() {
      const url = process.env.VUE_APP_API + `/users?role=1&coop_info.id=${this.$store.state.auth.info.coop_info.id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.token}`
        }
      }
      const response = await axios.get(url, config);
      const resData = response.data;
      this.memberAccounts = resData;
      console.log(resData)
    },
  }
})
</script>